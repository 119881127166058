import React, {useState, useEffect, useRef} from 'react';

export default function TypingTestQuestion(props) {
    const [wpm, setWpm] = useState(0);
    const [accuracy, setAccuracy] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [timer, setTimer]= useState(null);
    const [timerDuration, setTimerDuration]= useState(0);
    const [question, setQuestion] = useState('');
    const [userInput, setUserInput] = useState('');

    const textInputRef = useRef();

    const level_easy = "After a team scores a goal, the kick-off is taken by the other team. A throw-in is taken when the ball crosses a sideline and leaves the field. The two basic soccer rules for a proper throw-in are to have both feet on the ground and to throw the ball with both hands over the head.";
    const level_medium = "A wolf comes upon a lamb and, in order to justify taking its life, accuses it of various misdemeanours, all of which the lamb proves to be impossible. Losing patience, the wolf says the offences must have been committed by someone else in the family and that it does not propose to delay its meal by enquiring any further. The morals drawn are that the tyrant can always find an excuse for his tyranny, and that the unjust will not listen to the reasoning of the innocent";
    const level_difficult = "Zebras are several species of African equids (horse family) united by their distinctive black and white striped coats. Their stripes come in different patterns, unique to each individual. They are generally social animals that live in small harems to large herds. Unlike their closest relatives the horses and donkeys, zebras have never been truly domesticated. The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. However, various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction.";

    useInterval( () => {
        if (timer) {
            const newDuration = timerDuration + 1;
            setTimerDuration(newDuration);
            // Update properties
            var words = (userInput ? userInput : '').length / 5; // average a word of 5 chars
            //console.log('words = ' + words)
            //console.log(timerDuration)
            // Divide words by the number of minutes that have elapsed
            setWpm(Math.round((words / (timerDuration)) * 60 * 10));

        }
    }, 100);

    function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        // Remember the latest function.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        // Set up the interval.
        useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
      }

    const focusInput = () => {
        textInputRef.current.focus();
      };

    useEffect( () => {
        if (props.level == 'easy')
            setQuestion(level_easy);
        if (props.level == 'medium')
            setQuestion(level_medium);
        if (props.level == 'difficult')
            setQuestion(level_difficult);

        setAccuracy(0);
        setWpm(0);
        setTimer(null);
        setUserInput('');

        if (mounted) focusInput();
    }, [props.level]);

    useEffect( () => {        

        setMounted(true)

    }, [props.question]);

    const calculateAccuracy = (val) => {
        let correctChars = 0;

        for (var i = 0; i < val.length; i++) {
            if (question[i] === val[i])
                correctChars++;
        }

        const ret = correctChars/val.length * 100;
        return ret.toFixed(2);
    }


    const saveQuestionAnswers = async(val) => {    
        // If the timer hasn't started, start it
        if (!timer) {
            setTimer ('started')
        }

        // calculate accuracy
        const acc = calculateAccuracy(val);
        //console.log(acc);
        setAccuracy(acc);

        setUserInput(val);
        //response['R_2_Text'] = acc; // save accuracy

      
        // Check what is the current word and highlight it        
        const words = val.split(" ");
        const currentWord = words.length - 1;

        let s = '';
        const originalWords = question.replace(/\s\s+/g, ' ').split(" ");
        let highlightOriginalWords = originalWords.slice();  // copy array by value and not by ref in JS

        for (var i = 0; i < originalWords.length; i++) {
            if (i < currentWord)
                highlightOriginalWords[i] = "<span style='color:#bdbdbd'>" + highlightOriginalWords[i] + "</span>";

            if (i == currentWord)
                highlightOriginalWords[i] = "<span style='color:blue'>" + highlightOriginalWords[i] + "</span>";
            
            highlightOriginalWords[i] = " " + highlightOriginalWords[i];
            //s += highlightOriginalWords[i];
        }

        //document.getElementById('questionTitle').innerHTML = s;
        document.getElementById('questionTitle').innerHTML = highlightOriginalWords.join(''); //concatenates the array as string
        // end highlight
        
    };

    const btnReset = () => {
        setAccuracy(0);
        setWpm(0);
        setTimer(null);
        setUserInput('');

    }

    const btnDone = () => {
        setTimer(null);
    }
    if (!mounted)
        return '';

    else    
    return (

        <div>

            <div className='w-full text-lg' id='questionTitle'>{question}</div>

            <br />
            <textarea className='w-full' rows={5} ref={textInputRef} onChange={e=> {saveQuestionAnswers(e.target.value)}} value={userInput ? userInput : ''} placeholder="Type here to start .."></textarea>
            
            <br />

            <div className='flex text-lg'>
                <div>Speed: <span className='font-bold'>{wpm} wpm</span>,</div>
                <div className='ml-4'> Accuracy: <span className='font-bold'>{accuracy} %</span></div>
            </div>
            <br />

            <div className=''>
                <button onClick={() => btnDone()} className="inline-flex items-center justify-center px-2 py-2 text-base font-bold leading-7 text-white bg-blue-600 border border-transparent rounded-xl hover:bg-blue-800 focus:outline-none">I'm done</button>
                <button onClick={() => btnReset()} className="ml-4 inline-flex items-center justify-center px-2 py-2 text-base font-bold leading-7 text-white bg-gray-600 border border-transparent rounded-xl hover:bg-gray-800 focus:outline-none">Reset</button>
            </div>

         </div>
    )
}