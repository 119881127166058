import React, {useRef, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import TypingTestQuestion from '../components/TypingTestQuestion';



export default function TypingTest () {

    const [level, setLevel] = useState('easy');

    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Free Practice Typing Test By Skillmeter</title>

        </Helmet>

        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto sm:px-6 lg:px-8">
            <div className="px-8 mx-auto text-center md:px-0">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
                Practice Typing Test
              </h2>
              <p className="mt-6 text-lg font-normal text-gray-600 ">
              Select difficulty level

              <select className='border-black border-1 ml-4 ' onChange={e => setLevel(e.target.value)}>
                <option value='easy'>Easy</option>
                <option value='medium'>Medium</option>
                <option value='difficult'>Difficult</option>
              </select>
              </p>
            </div>

            {/* Form goes here*/}
            <div className="max-w-5xl mx-auto mt-10 sm:mt-20 justify-center">
                <TypingTestQuestion level={level}/>

            </div>

          </div>
        </section>





        <Footer />
        </>
    );
}